import React from "react"
import {Link} from "gatsby";
import {navigate} from "gatsby";
import styled from "styled-components";
import logo from "../../resources/img/logo/logo.svg";
import ContentContainer from "../Core/ContentContainer";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import Button from "./Header/Button";
import ButtonLink from "./Header/ButtonLink";
import LinkButton from "../Core/LinkButton";
import logoutIcon from "../../resources/img/icons/logout.svg";
import logoutActiveIcon from "../../resources/img/icons/logout-active.svg";
import settingsIcon from "../../resources/img/icons/settings.svg";
import settingsActiveIcon from "../../resources/img/icons/settings-active.svg";
import accountIcon from "../../resources/img/icons/account.svg";
import accountActiveIcon from "../../resources/img/icons/account-active.svg";

const HeaderContainer = styled.div`
  width: 100%;
  background: ${colors.white};
`;

const HeaderContent = styled.div`
  height: 56px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media(min-width: ${breakpoints.sm}) {
    height: 64px;
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DesktopOnly = styled.div`
  display: none;
  
  @media(min-width: ${breakpoints.sm}) {
    display: flex;
    
    a {
      margin-right: 16px;
    }
  }
`;

const LogoContainer = styled.div`  
`;

const LogoLink = styled(Link)`
  height: 32px;
  position: relative;
  display: block;

  @media(min-width: ${breakpoints.md}) {
    height: 40px;
  }
`;

const LogoImage = styled.img`
  height: 100%;
  display: block;
`;

const Header = () => {
  const logout = (e) => {
    e.preventDefault();

    localStorage.clear();
    navigate('/');
  };

  return (
    <HeaderContainer>
      <ContentContainer>
        <HeaderContent>
          <LogoContainer>
            <LogoLink to={'/'}>
              <LogoImage src={logo} alt={"Theme Deploy"} />
            </LogoLink>
          </LogoContainer>
          <Nav>
            <DesktopOnly>
              <LinkButton to={'/'}>
                Dashboard
              </LinkButton>
            </DesktopOnly>
            <ButtonLink to={'/settings'}
                        image={settingsIcon}
                        imageHover={settingsActiveIcon}
                        alt={"Settings"} />
            <ButtonLink to={'/account'}
                        image={accountIcon}
                        imageHover={accountActiveIcon}
                        alt={"Account"} />
            <Button onClick={(e) => logout(e)}
                    image={logoutIcon}
                    imageHover={logoutActiveIcon}
                    alt={"Logout"} />
          </Nav>
        </HeaderContent>
      </ContentContainer>
    </HeaderContainer>
  )
};

export default Header;
