import React, {useState} from "react"
import styled from "styled-components"
import colors from "../../../styles/colors";

const StyledButton = styled.button`
  padding: 8px 0 8px 16px;
  cursor: pointer;
  outline: none;
  background: ${colors.white};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = ({onClick, image, imageHover, alt}) => {
  const [isHover, setIsHover] = useState(false);

  const setHover = (e) => {
    e.preventDefault();

    setIsHover(!isHover);
  };

  return (
    <StyledButton onClick={onClick}
                  onMouseEnter={(e) => setHover(e)}
                  onMouseLeave={(e) => setHover(e)}>
      {isHover ?
        <img src={imageHover} alt={alt}/>
        : <img src={image} alt={alt}/>
      }
    </StyledButton>
  );
};

export default Button;
