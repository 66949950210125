import React from "react"
import ContentContainer from "../Core/ContentContainer";
import styled from "styled-components";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import ExternalLink from "../Core/ExternalLink";

const FooterContent = styled.div`
  margin: 80px 0 40px 0;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -12px;

  a {
    color: ${colors.black};
    ${fonts.montserratBold};
    text-decoration: none;
    font-size: 12px;
    margin-left: 12px;
    margin-right: 12px;
    line-height: 24px;   
    
    &:hover
    &.active {
      color: ${colors.green};
    }
  }
`;

const Copyright = styled.div`
  display: block;
  font-size: 12px;
  line-height: 24px;
  margin: 12px 0;
`;

const Footer = () => {
  const marketingSiteUrl = process.env.MARKETING_WEBSITE_URL;

  return (
    <ContentContainer>
      <FooterContent>
        <Nav>
          <ExternalLink href={`${marketingSiteUrl}/privacy-policy`}>Privacy Policy</ExternalLink>
          {/*<ExternalLink href={`${marketingSiteUrl}/documentation`}>Documentation</ExternalLink>*/}
        </Nav>
        <Copyright>
          © {new Date().getFullYear()} Theme Deploy
        </Copyright>
      </FooterContent>
    </ContentContainer>
  );
};

export default Footer;
