import React, {useState} from "react"
import styled from "styled-components"
import colors from "../../../styles/colors";
import {Link} from "gatsby";
import accountIcon from "../../../resources/img/icons/account.svg";

const StyledLink = styled(Link)`
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  background: ${colors.white};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLink = ({to, image, imageHover, alt}) => {
  const [isHover, setIsHover] = useState(false);

  const setHover = (e) => {
    e.preventDefault();

    setIsHover(!isHover);
  };

  return (
    <StyledLink to={to}
                onMouseEnter={(e) => setHover(e)}
                onMouseLeave={(e) => setHover(e)}
                activeClassName={"active"}>
      {isHover ?
        <img src={imageHover} alt={alt}/>
        : <img src={image} alt={alt}/>
      }
    </StyledLink>
  );
};

export default ButtonLink;
