import {createGlobalStyle} from 'styled-components';
import reset from 'styled-reset';
import montserratRegularWoff from "../resources/fonts/montserrat-regular-webfont.woff";
import montserratRegularWoff2 from "../resources/fonts/montserrat-regular-webfont.woff2";
import montserratMediumWoff from "../resources/fonts/montserrat-medium-webfont.woff";
import montserratMediumWoff2 from "../resources/fonts/montserrat-medium-webfont.woff2";
import montserratBoldWoff from "../resources/fonts/montserrat-bold-webfont.woff";
import montserratBoldWoff2 from "../resources/fonts/montserrat-bold-webfont.woff2";
import fonts from "./fonts";
import breakpoints from "./breakpoints";
import colors from "./colors";

const fontFace = (fontFamily, woff, woff2) => {
  return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }
    `;
};

const GlobalStyle = createGlobalStyle`
    ${reset}
    
    ${fontFace("Montserrat Regular", montserratRegularWoff, montserratRegularWoff2)}
    ${fontFace("Montserrat Medium", montserratMediumWoff, montserratMediumWoff2)}
    ${fontFace("Montserrat Bold", montserratBoldWoff, montserratBoldWoff2)}
    
    body {
      background: ${colors.grey};
      color: ${colors.black};
      ${fonts.montserratRegular};
      font-size: 14px;
      line-height: 23px;
      word-break: break-word;
      .intercom-lightweight-app-launcher {
        bottom: 84px;
      }
    }
    
    a {
      color: ${colors.black};
      text-decoration: underline;
    }
    
    p {
      margin-bottom: 0px;
    }
    
    b, strong {
      ${fonts.montserratBold};  
    }
  
    h1 {
      ${fonts.montserratBold};
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 32px;
      
      @media(min-width: ${breakpoints.md}) {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 32px;
      }
    }
    
    h2 {
      ${fonts.montserratBold};
      font-size: 20px;
      line-height: 24px;
      margin-top: 30px;
      margin-bottom: 12px;
      
      @media(min-width: ${breakpoints.md}) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    
    h3 {
      ${fonts.montserratBold};
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 8px;
    }
`;

export default GlobalStyle;
