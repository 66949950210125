import React, {useEffect, useState} from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import usage from "../../../backend/usage";
import {navigate} from "gatsby";
import LinkButtonGreen from "../LinkButtonGreen";

const Prefooter = styled.div`
  width: 100%;
  padding: 15px 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fonts.montserratMedium};
  font-size: 14px;
  line-height: 23px;
  border: 1px solid ${colors.borderGrey};
  border-left: none;
  border-right: none;
  position: fixed;
  left: 0;
  bottom: 0;
  background: ${colors.white};
  color: ${colors.black};
  z-index: 500;
  
  b {
    ${fonts.montserratBold};
    color: ${props => props.exceeded ? colors.red : colors.black};
  }
  
  span {
    margin-right: 20px;
  }

  a {
    padding: 8px 16px;
    text-align: center;

  }
`;

const FixedBar = () => {
  const isCancelled = React.useRef(false);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    updatePageData();

    return () => {
      isCancelled.current = true;
    };
  }, []);

  const updatePageData = () => {
    usage.getUsage()
      .then(({data}) => {
        if (isCancelled.current) {
          return;
        }

        setPageData(data);
      })
      .catch(() => {
        // alert("Error! Please try again");
      });
  };

  const hasPageData = pageData !== null;
  const showFixedBar = hasPageData && (pageData.isLimitExceeded || pageData.planCode === "trial" || pageData.planCode === "free")

  return (
    <>
      {
        showFixedBar &&
        <Prefooter exceeded={pageData.isLimitExceeded}>
          <span>You are currently on the {pageData.planName} Plan. <b>{pageData.isLimitExceeded && "Plan limit exceeded!"}</b></span>
          <LinkButtonGreen to={"/account"}>Upgrade now</LinkButtonGreen>
        </Prefooter>
      }
    </>
  )
};

export default FixedBar
