import api from "./core/api";

const BASE_URL = '/usage';

async function getUsage() {
  return await api.get(BASE_URL + '/');
}

const usage = {
  getUsage: getUsage
};

export default usage;
