import React from "react"
import styled from "styled-components"
import {Link} from "gatsby";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";

const StyledLink = styled(Link)`
  ${fonts.montserratBold};
  font-size: 12px;
  line-height: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  display: block;
  text-decoration: none;

  color: ${colors.white};
  background: ${colors.black};
  border: 1px solid ${colors.black};
  
  &:hover,
  &:active {
    background: ${colors.green};
    color: ${colors.white};
    border-color: ${colors.green};
  }
`;

const LinkButton = ({to, children}) => {
  return (
    <StyledLink to={to}>
      {children}
    </StyledLink>
  );
};

export default LinkButton
